<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item v-if="form.parentDeptCode" label="上级科室编码:">
        <span>{{ form.parentDeptCode }}</span>
      </el-form-item>
      <el-form-item v-if="form.parentDeptName" label="上级科室名称:">
        <span>{{ form.parentDeptName }}</span>
      </el-form-item>
      <el-form-item label="科室编码:" prop="deptCode">
        <el-input
          v-model="form.deptCode"
          v-check-permission
          placeholder="请输入科室编码"
          style="width: 30%"
          :disabled="$route.query.type == 1"
          maxlength="30"
        />
      </el-form-item>
      <el-form-item label="科室名称:" prop="deptName">
        <el-input
          v-model="form.deptName"
          v-check-permission
          placeholder="请输入科室名称"
          style="width: 30%"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveData()"> 保存 </el-button>
        <el-button @click="goBack"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { codeRule } from '@/utils/verificationRule.js';
export default {
  name: 'Details',
  data() {
    return {
      isBind: true, // 未绑定状态标准科室不可用
      dialogVisible: false, // 保存出现弹框
      selectId: '', // 编辑或查看当前数据的id
      normalDeptCode: '', // 标准科室得code
      standardDepartmentList: [],
      tagList: [], // 所属服务标签
      form: {
        parentDeptCode: '',
        parentDeptName: '',
        deptName: '',
        deptCode: '',
      },
      rules: {
        deptCode: [{ required: true, validator: codeRule, trigger: 'change' }],
        deptName: [
          {
            required: true,
            message: '请输入科室名称',
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getDepartmentDetail();
  },
  methods: {
    ...mapActions('basicDictionary', ['deptEdit']),
    // 科室详情
    getDepartmentDetail() {
      const detail = this.$route.query.detail;
      if (Number(this.$route.query.type) === 2) {
        this.form = {
          parentDeptCode: detail.deptCode,
          parentDeptName: detail.deptName,
          deptName: '',
          deptCode: '',
        };
      } else {
        this.form = {
          parentDeptCode: detail.parentDeptCode,
          parentDeptName: detail.parentDeptName,
          deptName: detail.deptName,
          deptCode: detail.deptCode,
        };
      }
    },
    // 编辑科室时，保存
    saveData() {
      const param = {
        ...this.form,
        id: this.$route.query.detail.id,
      };
      if (Number(this.$route.query.type) === 2) {
        delete param.id;
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.deptEdit(param).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功',
              });
              this.$router.go(-1);
            }
          });
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.serviceDetail {
  font-size: 16px;
  color: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.orderstatus {
  padding: 10px 5px;
  margin-left: 40px;
  border: 1px solid black;
}
</style>
